/* 57 Jobs / siegen tenant styles */

@import "../colors";
@import "../settings";

.kmta__tenant--siegen {
    a.siegen__logo {
        height: 87px;
        transform: translateY(45%);
        svg {
            width: 200px;
            height: 86px;
        }
    }
    .kmta__full-width a.siegen__logo {
        /* align-self: baseline; */ /* possible fix for logo */
    }

    .kmta__faqHomeInner {
        background-color: var(--gray20);
    }

    .siegen_svgIcon--logo {
        position: relative;
        bottom: 55px;
        width: 200px;
        height: 100%;
    }

    .kmta__tenantLogoAlt {
        width: 140px;
        height: 50px;
    }

    .kmta__heroTitle {
        padding-top: 3rem;
        margin-bottom: 4rem;
        font-size: 3rem;
        text-align: center;
        color: #848484;
    }

    .noty_theme__siegen {
        &.noty_type__success {
            border-bottom-color: var(--noty-success-border-color);
        }
    }

    // .kmta__menu > li a {
    //    color: #8c8c8c;
    //    &:hover {
    //        color: #00468f;
    //    }
    // }
    @include breakpoint(small down) {
        .kmta__headerTop {
            .kmta__tenantLogoAlt {
                display: none;
            }
            .kmta__headerUserinfo {
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }
        .kmta__headerBottomRow {
            justify-content: space-between;
            a.siegen__logo {
                //transform: translateY(-5.5rem);
                width: 50%;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
            .kmta__menu {
                justify-content: end;
                text-align: right;
            }
        }
    }
}
