$foundation-palette: (
    primary: #accb01,
    secondary: #767676,
    success: #3adb76,
    warning: #b11111,
    alert: #cc4b37
);

$white: #fefefe;
$black: #0a0a0a;
$gray10: #fafafa;
$gray20: #f3f3f3;
$gray30: #e4e4e4;
$gray40: #dbd7d7;
$gray50: #cccccc;
$gray60: #b2b2b2;
$gray70: #999999;
$gray80: #848484;
$gray90: #797979;
$gray100: #333333;
$light-gray: $gray10;
$medium-gray: $gray50;
$dark-gray: $gray100;
// #5999
$grayOldEditor: #eeeeee;
$grayOldEditor_RGB: 238; // R=G=B=0xEE=238
$red: #b11111;
$red-hover: #980f0f;
$green: #c7e696;
$green-light: #ebf5d6;
$green-light-alt: #dfec99;

.kmta_color--primary {
    color: var(--primary-color) !important;
}
.kmta_color--red {
    color: var(--red) !important;
}
.kmta_color--orange {
    color: var(--orange) !important;
}
.kmta_color--green {
    color: var(--green) !important;
}
.kmta_color--text {
    color: var(--gray100) !important;
}
.kmta_color--black {
    color: var(--black) !important;
}
.kmta_color--white {
    color: var(--white) !important;
}
.kmta_color--gray {
    color: var(--gray80) !important;
}
.kmta_color--gray90 {
    color: var(--gray90) !important;
}
.kmta_color--gray50 {
    color: var(--gray50) !important;
}
.kmta_color--link {
    color: var(--anchor-color) !important;
}

.kmta__bgColor--red {
    background-color: var(--red) !important;
    color: var(--white) !important;
}

.info {
    color: var(--red);
}
